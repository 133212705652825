import React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';


const Form = () => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const handleToClose = (event, reason) => {
    if ('clickaway' === reason) return;
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const options = [
    { value: 'Corporate', label: 'Corporate' },
    { value: 'NGOs', label: 'NGOs' },
    { value: 'School and Academic', label: 'School and Academic' },
    { value: 'Health', label: 'Health' },
    { value: 'Government', label: 'Government' },
    { value: 'others', label: 'others' },
  ];

  const onSubmit = async (data) => {
    axios({
      method: 'post',
      cors: true,
      url: '/send_contact_email',
      headers: { 'content-type': 'application/json' },
      data: data,
    })
      .then((result) => {
        if (result.data.status === 'success') {
          setOpen(true);
          setMessage('Thank you for contacting us');
          reset();
        } else {
          setOpen(true);
          setMessage('Something went wrong, please try again later');
          reset();
        }
      })

      .catch(
        (error) => console.error(error),
        setOpen(true),
        reset(),
        setMessage('Something went wrong, please try again later')
      );
  };

  return (
    <React.Fragment>
  
      <div
        className="max-w-7xl mx-auto p-5 lg:px-8 relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
        id="form-container"
      >
        <div
          id="form-border"
          className="grid grid-cols-1 md:grid-cols-12 mt-20 contact-page"
        >
          <div className="slate-100 md:col-span-4 p-10 text-white">
            <p className="mt-4 text-lg leading-7 font-regular uppercase">
              Maybe we could have a coffee
            </p>
            <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight">
              Get In <span className="text-indigo-500">Touch</span>
            </h3>
            <p className="mt-4 leading-7 text-zinc-900">
              Technology surely reduces time, but for certain things it's better
              to have a face-to-face meeting. Whether it's to talk about your
              project or just for a simple question, we would be happy to meet
              with you in person.
            </p>

            <div className="flex items-center mt-5">
              <span className="text-sm">
                Manfred-von-Richthofen-Straße 4 - IV. OG,D–12101 Berlin
              </span>
            </div>
            <div className="flex items-center mt-5">
              <span className="text-sm">
                <a href="tel:00493021461108"> +49 30 214 611 08</a>
              </span>
            </div>
            <div className="flex items-center mt-5">
              <span className="text-sm">
                <a href="tel:004917674725686">+49 176 747 25 686</a>
              </span>
            </div>
            <div className="flex items-center mt-5">
              <span className="text-sm">
                <a href="mailto:info@abstract-technology.de">
                  info@abstract-technology.de
                </a>
              </span>
            </div>
          </div>
          <form
            className="md:col-span-8 p-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  First Name *
                </label>
                <input
                  className="appearance-none bg-transparent border-b border-indigo-500 w-full text-gray-700 mr-3 p-4 leading-tight focus:outline-none focus:bg-gray-200"
                  type="text"
                  placeholder="Your first name"
                  aria-label="first name"
                  {...register('firstName', {
                    required: 'This field is required',
                    maxLength: {
                      value: 10,
                      message: 'First Name must be less than 10 characters ',
                    },
                    minLength: {
                      value: 2,
                      message: 'First Name must be at least 2 letters',
                    },
                    pattern: {
                      value: /[a-zA-Z]/,
                      message: 'First Name must only include letters',
                    },
                  })}
                />
                {errors.firstName && (
                  <div className="my-3 text-normal text-red-500">
                    {errors.firstName.message}
                  </div>
                )}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Last Name *
                </label>
                <input
                  className="appearance-none bg-transparent border-b border-indigo-500 w-full text-gray-700 mr-3 p-4 leading-tight focus:outline-none focus:bg-gray-200"
                  type="text"
                  placeholder="Your last name"
                  aria-label="last name"
                  {...register('lastName', {
                    required: 'This field is required',
                    maxLength: {
                      value: 10,
                      message: 'Last Name must be less than 10 characters ',
                    },
                    minLength: {
                      value: 2,
                      message: 'Last Name must be at least 2 letters',
                    },
                    pattern: {
                      value: /[a-zA-Z]/,
                      message: 'Last Name must only include letters',
                    },
                  })}
                />
                {errors.lastName && (
                  <div className="my-3 text-normal text-red-500">
                    {errors.lastName.message}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email Address *
                </label>
                <input
                  className="appearance-none bg-transparent border-b border-indigo-500 w-full text-gray-700 mr-3 p-4 leading-tight focus:outline-none focus:bg-gray-200"
                  type="email"
                  placeholder="Your Email"
                  aria-label="email address"
                  name="email"
                  {...register('email', {
                    required: 'This field is required',
                    maxLength: {
                      value: 40,
                      message: 'Email must be less than 40 characters ',
                    },
                    minLength: {
                      value: 8,
                      message: 'Email must be at least 8 characters',
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

                      message: 'Please enter a valid email address',
                    },
                  })}
                />
                {errors.email && (
                  <div className="my-3 text-normal text-red-500">
                    {errors.email.message}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-organization"
                >
                  Organization *
                </label>
                <input
                  className="appearance-none bg-transparent border-b border-indigo-500 w-full text-gray-700 mr-3 p-4 leading-tight focus:outline-none focus:bg-gray-200"
                  type="text"
                  placeholder="your organization"
                  aria-label="organization"
                  {...register('Organization', {
                    required: 'This field is required',
                    maxLength: {
                      value: 30,
                      message: 'Organization must be less than 30 characters',
                    },
                    minLength: {
                      value: 3,
                      message: 'Organization must be less than 3 characters',
                    },
                  })}
                />
                {errors.Organization && (
                  <div className="my-3 text-normal text-red-500">
                    {errors.Organization.message}
                  </div>
                )}
              </div>
            </div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-sector"
            >
              Your sector *
            </label>
            <select
              className="form-select appearance-none
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      mb-5
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              {...register('sector', { required: 'This field is required' })}
              type="select"
            >
              <option value=""> -- select an option -- </option>

              {options.map(({ value, label }, index) => (
                <option key={index} value={value}>
                  {label}
                </option>
              ))}
            </select>
            {errors.sector && (
              <div className="my-3 text-normal text-red-500">
                {errors.sector.message}
              </div>
            )}

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Your Message *
                </label>
                <textarea
                  rows="10"
                  className="appearance-none bg-transparent border-b border-indigo-500 w-full text-gray-700 mr-3 p-4 focus:bg-gray-200 leading-tight focus:outline-none"
                  type="text"
                  placeholder=" Write something ..."
                  aria-label="message"
                  {...register('msg', {
                    required: 'This field is required',
                    minLength: {
                      value: 5,
                      message: 'Message must be at least 5 letters',
                    },
                  })}
                ></textarea>
                {errors.msg && (
                  <div className="my-3 text-normal text-red-500">
                    {errors.msg.message}
                  </div>
                )}
              </div>
              <div className="flex justify-between w-full px-3">
                <div className="md:flex md:items-center">
                  <button
                    id="form-button"
                    className="cursor-pointer block w-full py-3 px-4 rounded-md shadow bg-indigo-500 text-white font-medium hover:bg-indigo-600 focus:outline-none focus:ring-2 hover:no-underline focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900 mt-5 demo-btn"
                    type="submit"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>

            <Snackbar
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              open={open}
              autoHideDuration={10000}
              message={message}
              onClose={handleToClose}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleToClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          </form>
        </div>
      </div>

    </React.Fragment>
  );
};

export default Form;
