import Images from '../../helper-logos';

const Logos = [
  {
    title: 'Renewables Grid Initiative',
    image: Images['RGI.jpg'],
  },

  {
    title: 'Hochschule Osnabrück',
    image: Images['hoschschule.jpg'],
  },

  {
    title: 'Freie Universität Berlin',
    image: Images['universitat-berlin.jpg'],
  },

  {
    title: 'Zukunft Lernen',
    image: Images['zukunft.jpg'],
  },

  {
    title: 'Novareto',
    image: Images['novareto.jpg'],
  },
  {
    title: 'Die BG ETEM',
    image: Images['bg-etem.jpg'],
  },

  {
    title: 'Digital Druck Zentrum — DDZ',
    image: Images['ddz.jpg'],
  },

  {
    title: 'der Freitag',
    image: Images['derfreitag.jpg'],
  },
  {
    title: 'BG Verkehr',
    image: Images['bg-verkehr.jpg'],
  },
  {
    title: 'Die BGHW',
    image: Images['bghw.jpg'],
  },

  {
    title: 'The United Nations Institute for Training and Research (UNITAR)',
    image: Images['unitar-logo.jpg'],
  },
  {
    title: 'Mannheim Business School',
    image: Images['mannheim-businees-school-logo.jpg'],
  },
  {
    title: 'GFU Cyrus AG – Ihr Partner für IT-Schulungen',
    image: Images['gfu-cyrus-ag.jpg'],
    padding: 'no-padding',
  },

  {
    title: 'Die TUM',
    image: Images['logostum.jpg'],
  },

  {
    title: 'EXASOL',
    image: Images['logoexasol.jpg'],
  },

  {
    title: 'close2real',
    image: Images['logosc2r.jpg'],
  },

  {
    title: 'Reporterfabrik',
    image: Images['rf.png'],
  },
  {
    title: 'AnotherMonday',
    image: Images['logo_am.jpg'],
  },

  {
    title: 'ESSEC',
    image: Images['logosessec.jpg'],
  },
  {
    title: 'IEA - International Energy Agency',
    image: Images['iea-logo.jpg'],
  },
  {
    title: 'Buergerakademie',
    image: Images['ba-logo.jpg'],
  },
  {
    title: 'UEG',
    image: Images['ueg_logo.jpg'],
  },
  {
    title: 'Mediendienstintegration',
    image: Images['mdi-logo.jpg'],
  },
  {
    title: 'Kreiswert',
    image: Images['kreiswert-logo.jpg'],
  },
  {
    title: 'GestOnline',
    image: Images['gest-logo.jpg'],
  },
  {
    title: 'WZB',
    image: Images['wzb-logo.jpg'],
  },

  {
    title: 'Limes',
    image: Images['limes-logo.jpg'],
  },
  {
    title: 'Tools of the Mind',
    image: Images['tom-logo.jpg'],
  },

  {
    title: 'polito',
    image: Images['polito-logo.jpg'],
  },

  {
    title: 'DigiCirc',
    image: Images['digicirc-logo.jpg'],
  },
  {
    title: 'IT ON BOARD',
    image: Images['itonboard-logo.jpg'],
  },
];

const FirstLogosSilder = () => {
  return (
    <div className="logos-container">
      <div className={'logo'}>
        {Logos.map((img) => (
          <img
            key={img.title}
            src={img.image}
            className={img.padding}
            alt={img.title}
            title={img.title}
            id="logos-image"
          />
        ))}
      </div>
    </div>
  );
};

export default FirstLogosSilder;
