export const setCourseslength = (length) => {
  const oneCourse = " course";
  const manyCourse = " courses";
  if (length === 1) {
    return length + oneCourse;
  } else {
    return length + manyCourse;
  }
};


