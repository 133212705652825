import React from 'react';
import styled, { keyframes } from 'styled-components';
import { bounce } from 'react-animations';

const Bounce = styled.div`
  animation: 7s ${keyframes`${bounce}`} infinite;
`;
const Help = () => {
  return (
    <div className="relative slate-100">
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto ">
          <Bounce>
            <h2 className="text-3xl font-semibold uppercase tracking-wider text-zinc-900 head project-text">
              OUR <span>ENGAGEMENT</span>
            </h2>
          </Bounce>
          <p className="mt-2 text-zinc-900 text-3xl font-extrabold tracking-tight sm:text-4xl project-text-secondary">
            Abstract-Technology is official and active partner of the ERASMUS+
            project ITONBOARD.
          </p>
          <p className="mt-3 text-lg  text-zinc-900 project-text">
            ITONBOARD introduces young people to career orientation
            possibilities in IT with a focus on open source software
            communities.The onboarding program extends from gamified career
            orientation activities to in-depth e-learning to facilitating the
            involvement of companies in a practical component. ITONBOARD is a
            European project launched with the support of the ERASMUS+ programme
            of the European Union. As a multinational IT company with a focus on
            open source technologies, we want to actively participate in the
            project ITONBOARD in order to foster young talented people in the IT
            sector and in the field of open source technology in particular.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="https://www.itonboard.eu/"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-sm text-gray-900 bg-white hover:bg-gray-50 demo-btn"
              >
                About the project
                <svg
                  className="-mr-1 ml-3 h-5 w-5 text-stone-900"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
