import React, { useState, useEffect, createContext } from 'react';
import './App.css';
import CEO from './components/Content/CEO';
import Help from './components/Content/Help';
import Intro from './components/Content/Intro';
import axios from 'axios';
import Course from './components/Content/Course';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Customer from './components/Content/Customer';
import Images from './helper';
import { setCourseslength } from './utility';
import { motion, useScroll } from 'framer-motion';
import Carousel from 'react-elastic-carousel'



const btnList = [
  {
    title: 'Technology',
    image: Images['category-card.jpg'],
  },

  {
    title: 'Business',
    image: Images['category-card2.jpg'],
  },

  {
    title: 'Programming',
    image: Images['category-card3.jpg'],
  },

  {
    title: 'Health',
    image: Images['category-card4.jpg'],
  },

  {
    title: 'Management',
    image: Images['category-card.jpg'],
  },
  {
    title: 'Career',
    image: Images['category-card2.jpg'],
  },
  {
    title: 'ITONBOARD',
    image: Images['category-card3.jpg'],
  },
  {
    title: 'Abstract',
    image: Images['category-card.jpg'],
  },
];

export const ThemeContext = createContext(null);
function App() {
  const [data, setData] = useState(null);
  const [activeButtonId, setActiveButtonId] = useState(null);
  const [isOneActive, setIsOneActive] = useState(null);


  const settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '60px',
    slidesToScroll: 3,
    slidesToShow: 3,
    speed: 600,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          centerPadding: '10px',
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const update_data = async function () {
    const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];
    let BASE_URL_PUBLIC = '';

    /* offline */
    if (LOCAL_DOMAINS.includes(window.location.hostname)) {
      BASE_URL_PUBLIC = 'http://lilac-derex.localhost/api/courses/v1/courses/';
    } else {
      /* online || production && staging */
      BASE_URL_PUBLIC = '/api/courses/v1/courses/';
    }

    const result = await axios(BASE_URL_PUBLIC, {
      params: { page_size: 100 },
    });

    setData(
      result.data.results.sort((a, b) => new Date(b.start) - new Date(a.start))
    );
  };

  let filteredData = null;
  if (activeButtonId != null) {
    filteredData = data
      ? data.filter((el) => {
          return (
            btnList[activeButtonId].title.toLowerCase() === el.org.toLowerCase()
          );
        })
      : [];
  }

  useEffect(() => {
    update_data();
  }, []);

  const handleToggle = (e, btn_id) => {
    if (e.target.parentNode.classList.contains('is_active')) {
      setIsOneActive(null);
      setActiveButtonId(null);
    } else {
      setIsOneActive('not_active');
      setActiveButtonId(btn_id);
    }
  };

  const { scrollYProgress } = useScroll();


  return (
      <React.Fragment>
        <motion.div
            className="progress-bar"
            style={{ scaleX: scrollYProgress }}
          />
          <Intro />
          <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
            <div className="absolute inset-0">
              <div className=" h-1/3 sm:h-2/3"></div>
            </div>
            <div className="relative max-w-7xl mx-auto course-container">
              <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl mb-10 browse-text">
                Browse our newest learning content
              </h2>

              <Slider {...settings}>
                {data?.map((courseInfo) => (
                  <div key={courseInfo.id}>
                    <Course key={courseInfo.id} course={courseInfo} />
                  </div>
                ))}
              </Slider>
            </div>
            {/* original text: Start Learning with free courses or Discover lifelong learning */}
            <div className="relative mt-20 max-w-7xl  mx-auto">
              <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl mb-10 learning-text">
                Discover lifelong <span>learning</span> with free courses
              </h2>

              <div className="card-container">
                {/* <Slider {...settings}>
                  {btnList.map((btn, btn_id) => (
                    <div
                    style={{ width: 175 }}
                      key={btn_id}
                      className={
                        btn_id === activeButtonId ? 'is_active' : isOneActive
                      }
                      name={btn.title}
                      onClick={(event) => handleToggle(event, btn_id)}
                      id="menuButton"
                    >
                      <img
                        src={btn.image}
                        alt={btn.title}
                        className="img-card"
                      />

                      <h3 className="card-title">{btn.title}</h3>
                    </div>
                  ))}
                </Slider> */}
                <Carousel itemsToShow={3}>
                {btnList.map((btn, btn_id) => (
                    <div
                      key={btn_id}
                      className={
                        btn_id === activeButtonId ? 'is_active' : isOneActive
                      }
                      name={btn.title}
                      onClick={(event) => handleToggle(event, btn_id)}
                      id="menuButton"
                    >
                      <img
                        src={btn.image}
                        alt={btn.title}
                        className="img-card"
                      />

                      <h3 className="card-title">{btn.title}</h3>
                    </div>
                  ))}
              </Carousel>

                
                {filteredData && filteredData.length > 0 ? (
                  <div className="filter-result p-5 mb-10">
                    <span className="course_length">
                      {' '}
                      {setCourseslength(filteredData.length)} | Language:
                      English{' '}
                    </span>

                    <p className="org_name">{filteredData[0].org}</p>

                    <br />

                    <Slider {...settings}>
                      {filteredData.map((courseInfo) => (
                        <div key={courseInfo.id}>
                          <Course course={courseInfo} />
                        </div>
                      ))}
                    </Slider>
                  </div>
                ) : (
                  <React.Fragment />
                )}
              </div>
            </div>
          </div>

          <Help />
          <CEO />
          <Customer />
  
    
      </React.Fragment>
  
  );
}

export default App;
