import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import bannerImage from '../../Images/cloud-illustration-indigo-400.svg';

const Intro = () => {
  AOS.init({
    once: true,
  });

  return (
    <div className="content-header slate-100 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden lg:px-8">
      <div className="mx-auto max-w-7xl lg:px-8 sm:pt-20">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl text-left">
                <span className="block-intro title">
                  Abstract<span className="text-indigo-600 dash">-</span>
                  Technology
                </span>
                <span className="block-intro-second title">
                  Digital Learning.
                </span>
              </h1>
              <p className="mt-3 text-base text-zinc-900 sm:mt-5 sm:text-xl lg:text-lg xl:text-2xl banner-text">
                We develop innovative and individual digital learning solutions
                for enterprises and educational institutions.
              </p>
              <div className="mt-10 sm:mt-6 first-btn">
                <div className="sm:flex">
                  <div className="mt-3 sm:mt-0">
                    <a
                      type="submit"
                      href="/contact-form"
                      className="cursor-pointer block w-full py-3 px-4 rounded-sm shadow text-white demo-btn"
                    >
                      REQUEST A DEMO
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative banner-section">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 image-wrapper">
              <img
                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={bannerImage}
                alt="Abstract's banner, clouds and virtual reality"
                id="banner-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
