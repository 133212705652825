import React from 'react';
import StefaniaImage from '../../Images/stefania_image.jpg';

const CEO = () => {
  return (
    <section className="bg-white overflow-hidden ceo-section">
      <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
        <div className="relative lg:flex lg:items-center">
          <div className="lg:block lg:flex-shrink-0">
            <img
              className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
              src={StefaniaImage}
              alt=""
            />
          </div>

          <div className="relative lg:ml-10">
            <blockquote className="relative">
              <div className="text-2xl leading-9 font-medium text-gray-900">
                <p className="ceo-paragraph">
                  Are you interested in knowing how delivering courses online
                  can improve your teaching and offer unprecedented learning
                  opportunities? Abstract Technology aims to enhance the online
                  learning experience and deliver the best digital solution for
                  all.
                </p>
              </div>
              <footer className="mt-8">
                <div className="flex">
                  <div className="flex-shrink-0 lg:hidden">
                    <img
                      className="h-12 w-12 rounded-full"
                      src={StefaniaImage}
                      alt="CEO of Abstract"
                    />
                  </div>
                  <div className="ml-4 lg:ml-0">
                    <div className="text-2xl font-medium text-gray-900 ceo-text-name">
                      Stefania Trabucchi
                    </div>
                    <div className="text-xl font-medium text-indigo-600 ceo-text">
                      CEO of Abstract Technology GmbH
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CEO;
