import React from "react";

const Course = (data) => {
  const date = (str) => {
    let unformatData = new Date(str);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return unformatData.toLocaleDateString("en-US", options);
  };

  const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
  let BASE_URL_PUBLIC = "";

  /* offline */
  if (LOCAL_DOMAINS.includes(window.location.hostname)) {
    BASE_URL_PUBLIC = "http://lilac-derex.localhost/";
  } else {
    /* online || production && staging */
    BASE_URL_PUBLIC = "";
  }

  return (
    <div className="transform transition duration-500 hover:scale-105 card rounded-shape course-div">
      <a
        href={BASE_URL_PUBLIC + "courses/" + data.course.course_id + "/about"}
        className="group hover:no-underline focus:no-underline transition delay-150 duration-300 ease-in-out transform hover:scale-110 hover:rounded-lg"
      >
        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden demo-hover">
          <div className="flex-shrink-0">
            <img
              className="h-52 w-full object-cover course-div"
              src={data.course.media?.image.large}
              alt={data.course.course_id}
            />
          </div>
          <div className="flex-1 p-6 flex flex-col justify-between min-h-[10rem]">
            <div className="flex-1">
            <p className="text-xl font-semibold text-zinc-900 group-hover:text-white">
                {data.course.org}
              </p>
              <p className="text-xl font-semibold text-zinc-900 group-hover:text-white">
                {data.course.name}
              </p>
              <p className="mt-3 text-base text-zinc-900 group-hover:text-white">
                {data.course.short_description}
              </p>
              <div className="flex space-x-1 text-sm text-zinc-900 date-course-text">
                Started - {date(data.course.start)}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Course;
