import React from 'react';
import FirstLogosSilder from './LogosSlider';
const Customer = () => {
  return (
    <section className="bg-white overflow-hidden client-section">
      <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20 client-section-div" >
        <h2 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-4xl mb-10 customer-text">
          We have been chosen by
        </h2>
        <FirstLogosSilder />
      </div>
    </section>
  );
};

export default Customer;
