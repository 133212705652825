import { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import Logo from '../../Images/FooterLogo.svg';
import { NavLink } from 'react-router-dom';
import Switch from '@mui/material/Switch';

export default function Header({ mode, handleToggle }) {
  const [animateHeader, setAnimateHeader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const listener = () => {
      if (window.scrollY > 100) {
        setAnimateHeader(true);
      } else setAnimateHeader(false);
    };
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return (
    <header
      id="header-section"
      className={`w-full shald backdrop-filter header fixed z-10 trasition ease-in-out duration-500 ${
        animateHeader && 'shadow-xl'
      }`}
    >
      <div className="max-w-7xl mx-auto p-2 ">
        <div
          className={`flex max-w-screen-xl py-10 mx-auto items-center justify-between px-8 trasition ease-in-out duration-500`}
        >
          <nav className={`absolute z-50 w-full left-0 nav-mobile`}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex-shrink-0">
                  <a href="/">
                    {animateHeader ? (
                      <img className="h-10 logo" src={Logo} alt="Workflow" />
                    ) : (
                      <img className="h-10 logo" src={Logo} alt="Workflow" />
                    )}
                  </a>
                </div>
                <div className="flex items-center">
                  <div className="hidden md:block flex-1 w-auto text-right">
                    <div className="ml-10 flex items-center space-x-4">
                      <NavLink
                        to="/"
                        className="px-3 py-2 hover:border-b-2 hover:border-b-white font-medium navlink text-2xl transition-all"
                      >
                        Home
                      </NavLink>
                      <a
                        href="/dashboard"
                        className="px-3 py-2 hover:border-b-2 hover:border-b-white font-medium navlink text-2xl transition-all"
                      >
                        Dashboard
                      </a>

                      <NavLink
                        to="/news"
                        className="px-3 py-2 hover:border-b-2 hover:border-b-white font-medium navlink text-2xl transition-all"
                      >
                        News
                      </NavLink>
                      <NavLink
                        to="/contact-form"
                        className="px-3 py-2 hover:border-b-2 hover:border-b-white font-medium navlink text-2xl transition-all"
                      >
                        Contact
                      </NavLink>

                      <div className="switch">
                        {/* <ReactSwitch onChange={handleToggle} checked={mode} /> */}
                        <Switch className="mui-switch" onChange={handleToggle}  size='large' />
                                   </div>
                    </div>
                  </div>
                </div>
                <div className="flex md:hidden">
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    type="button"
                    id="hamburger-btn"
                    className="inline-flex items-center justify-center p-2 rounded-md text-stone-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white navlink "
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                  >
                    <span className="sr-only">Open main menu</span>
                    {!isOpen ? (
                      <svg
                        className="block h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="block h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <Transition
              show={isOpen}
              enter="transition ease-out duration-100 transform"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75 transform"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {(ref) => (
                <div className="md:hidden" id="mobile-menu">
                  <div
                    ref={ref}
                    className={`px-2 pt-2 -mt-1  pb-3 space-y-1 sm:px-3 ${
                      animateHeader && 'bg-white'
                    } `}
                  >
                    <a
                      href="/"
                      className="text-stone-900 hover:bg-indigo-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    >
                      {' '}
                      Home
                    </a>

                    <a
                      href="/dashboard"
                      className="text-stone-900 hover:bg-indigo-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    >
                      Dashboard
                                </a>

                    <a
                      href="/News"
                      className="text-stone-900 hover:bg-indigo-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    >
                     News
                    </a>
                    <a
                      href="/contact-form"
                      className="text-stone-900 hover:bg-indigo-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              )}
            </Transition>
          </nav>
        </div>
      </div>
    </header>
  );
}
