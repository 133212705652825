import React, {useState} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Routes, 
  Route,
} from "react-router-dom";
import Form from "./components/Contact/Form";

import Wrapper from "./components/Wrapper.jsx";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import News from "./components/News/News";

function Index() {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "dark" ? "light" : "dark"));
  };
  return (
    <BrowserRouter>
      <Wrapper>
        <div className="app" id={theme}>
          <Header mode={theme === "light"} handleToggle={toggleTheme} />
          <Routes>
            <Route exact path="/" element={<App />} />
            <Route exact path="contact-form" element={<Form />} />
            <Route exact path="news" element={<News />} />
          </Routes>
          <Footer color={theme}/>
        </div>
      </Wrapper>
    </BrowserRouter>
  );
}

ReactDOM.render(<Index />, document.getElementById("root"));
reportWebVitals();
