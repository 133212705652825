import React from "react";
import { useState } from 'react';
//import { slice, concat } from "lodash";
import data from "../../data.json";

const LIMIT = 2;

const News = () => {
  const [showMore, setShowMore] = useState(true);
  const [index, setIndex] = useState(LIMIT);


  const loadMore = () => {
    const newIndex = index + LIMIT;
    const newShowMore = newIndex < data.length;
    setIndex(newIndex);
    setShowMore(newShowMore);
  };

  return (
    <>
      <div className="news-container">
        <h2 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight news-title">
          NEWS
        </h2>
        
        <div className="news-box">
            {data?.slice(0, index).map(({ title, date, header, img, content, key}) => {
              return (
                <div className="news-section-box" key={key}>
                  <h2 className="data-title">{title}</h2>
                  <h4 className="data-date">{date}</h4>
                  <p className="data-header">{header}</p>
                  <img className="data-img" src={img} alt="blog" />
                 {/*<p>{content}</p>*/} 
                 <div className="btn-container">
                  <button className="cursor-pointer block w-full py-2 px-2 rounded-md shadow text-white demo-btn">
                    READ MORE
                  </button>
                </div>
                </div>
              );
            })}
          </div>
          {data?.length > LIMIT ? (
          showMore && (
            <div className="btn-container">
              <button className="cursor-pointer block w-full py-2 px-2 rounded-md shadow view-more-btn" onClick={loadMore}>
                VIEW MORE
              </button>
            </div>
          )
        ) : (
          <></>
        )}
        </div>
      
    </>
  );
};



export default News;

